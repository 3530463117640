<template>
  <v-container
    fluid>
    <v-row>
      <v-col
        cols="12"
        :md="selectItem ? '8' : '12'"
        class="table-detail">
        <v-data-table
          :headers="header"
          :items="items"
          :options.sync="options"
          :items-per-page="options.itemsPerPage"
          :server-items-length="total"
          :loading="loading"
          :search="inputSearch"
          :footer-props="{'items-per-page-options': [15, 25, 50, 100]}"
          class="w-full"
          @update:options="getData()"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>
                <span class="capitalize"> BC Logs </span>
              </v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical />
              <v-spacer />
              <v-text-field
                ref="searchTextField"
                v-model.trim="inputSearch"
                class="mb-2 mr-5 search-text-field"
                label="Search"
                hide-details
                @keydown.enter="getData()" />
              <v-btn
                small
                outlined
                @click="getData()">
                Search
              </v-btn>
            </v-toolbar>
          </template>

          <template #[`item.createdAt`]="{ item }">
            {{ item.createdAt | dateTimeFormat() }}
          </template>
          <template #[`item.description`]="{ item }">
            <span
              class="text-bold"
              :style="getStatusStyle(item.httpStatus)">{{ item.httpStatus }}</span> {{ item.description }}
          </template>
          <template #[`item.option`]="{ item }">
            <v-btn
              v-if="!selectItem || selectItem.id !== item.id"
              link
              icon
              x-small
              @click="showItemDetail(item)">
              <v-icon>
                mdi-magnify
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col
        v-if="selectItem"
        cols="12"
        md="4"
        class="pa-0">
        <div class="log-detail">
          <h3 class="text-bold mb-0">
            {{ selectItem.description }}
          </h3>
          <p class="mb-0 mt-2">
            <span
              :style="getStatusStyle(selectItem.httpStatus)"
              class="text-bold">{{ selectItem.httpStatus }}</span> {{ selectItem.statusText }}
          </p>
          <p class="mb-0">
            <b>Date </b>{{ selectItem.createdAt | dateTimeFormat() }}
          </p>
          <p class="mb-0">
            <b>Document NO </b> {{ selectItem.documentNo }}
          </p>
          <div class="mt-4">
            <a @click="scrollTo('Config')">#Config</a>
            <a
              class="ml-4"
              @click="scrollTo('Body')">#Body</a>
            <a
              class="ml-4"
              @click="scrollTo('Response')">#Response</a>
          </div>
          <div style="border: 1px solid #000;">
            <json-viewer
              :value="{ method: selectItem.method, baseUrl: selectItem.baseUrl }"
              :expand-depth="1"
              copyable />
          </div>
          <p
            ref="Config"
            class="mt-4">
            Config
          </p>
          <div style="border: 1px solid #000;">
            <json-viewer
              :value="selectItem.config"
              :expand-depth="1"
              copyable />
          </div>
          <p
            ref="Body"
            class="mt-4">
            Body
          </p>
          <div style="border: 1px solid #000;">
            <json-viewer
              :value="selectItem.body"
              :expand-depth="5"
              copyable
              sort />
          </div>
          <p
            ref="Response"
            class="mt-4">
            Response
          </p>
          <div style="border: 1px solid #000;">
            <json-viewer
              :value="selectItem.result"
              :expand-depth="5"
              copyable
              sort />
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BCLogProvider from '@/resources/BCLogProvider'
// import BcLogDetail from '../components/BcLogDetail.vue'

const BcLogService = new BCLogProvider()

export default {
  components: {
    // BcLogDetail
  },
  data () {
    return {
      inputSearch: '',
      loading: false,
      selectItem: null,
      showDetail: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ['id'],
        sortDesc: [true]
      },
      header: [
        {
          text: 'Date',
          value: 'createdAt'
        },
        {
          text: 'Description',
          value: 'description'
        },
        {
          text: 'Document No',
          value: 'documentNo'
        },
        {
          text: 'Options',
          value: 'option',
          align: 'center'
        }
      ],
      items: []
    }
  },
  created () {
    this.getData()
  },
  methods: {
    showItemDetail (item) {
      this.selectItem = item
      this.showDetail = true
    },
    scrollTo (tag) {
      const el = this.$refs[tag]
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' })
      }
    },
    getStatusStyle (status) {
      if (+status >= 200 && +status < 300) {
        return 'color: #4CAF50;'
      }
      if (+status < 600) {
        return 'color: #F44336'
      }

      return ''
    },
    async getData (clear = false) {
      if (clear) {
        this.options.page = 1
      }
      try {
        this.loading = true
        const { data: { results, total } } = await BcLogService.getBcLogs(
          {
            page: this.options.page,
            limit: this.options.itemsPerPage,
            sortBy: this.options.sortBy[0],
            sortOrder: this.options.sortDesc[0] ? 'desc' : 'asc',
            search: this.inputSearch
          }
        )
        this.items = results
        this.total = total
      } catch (error) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `Error ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style scoped>
.table-detail {
  height: calc(100vh - 80px);
  overflow-y: auto;
  background-color: #fff;
}
.log-detail {
  width: 100%;
  margin-left: 10px;
  padding: 10px;
  height: 100%;
  overflow-y: auto;
  height: calc(100vh - 80px);
  background-color: #fff;
}
</style>
